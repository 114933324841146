<template>
<div class="apply-page">
  <Topbar2></Topbar2>
  <div class="login-page">
    <div class="bg"></div>
    <div class="ikon">
        <div class="settop">
            <span class="first">1</span>
          <p class="first_text">签署开放充值平台入驻协议</p>
          <p class="complateline"></p>
          <span class="first">2</span>
          <p class="first_text" >入驻平台成功</p>
        </div>
      <div class="reset-form">
          <img src="@/assets/prosper.png" class="prosper">
          <p>恭喜您入驻成功</p>
          <div>请到<span @click="to_manage()">我的管理</span>板块内查看</div> 
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Topbar2 from '@/components/topbar2'
export default {
  name: "apply-complated",
  // inject:['app'],
  components: { 
  Topbar2
  },
  data() {
    return {
      moble: "",
      idnum:"",
      isreason:"false",
      code:""
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
    var that=this;
          let params={'p_id':this.$store.state.user.p_id};
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/myAdmin',
          data:params,
          dataType:'json',
          success:function(res){
              that.$store.commit('user/u_type',res.data.virtual)
          },
        })
  },
  methods: {
    to_manage(){
        this.$router.push('./my_manage');
    }
  }
};
</script>

<style lang="less" scoped>
.apply-page {
  position: relative;
  padding: 0px;
  margin: 0;
  width: 100%;}
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 720px;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(242, 243, 250, 1);
    z-index: -10;
  }
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
  > img {
    width: 100%;
    height: 100%;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1300px;
  height: 90%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(222, 222, 222, 1);
  border-radius: 8px;
  > img {
    width: 570px;
    height: 100%;
  }
  .settop{height: 40px;
      width: 750px;
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      display: inline-block;
      margin: 0 auto;
  >span{display: inline-block;}
  >p{display: inline-block;}
  .first{
      width: 36px;
      height: 36px;
      text-align: center;
      background:rgba(255,255,255,1);
    border:2px solid rgba(222,222,222,1);
    border-radius:50%;
    font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(61,49,226,1);
line-height:32px;margin-left: 18px;}
.first_text{
height:20px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(55,67,214,1);
line-height:26px;
margin: 0;
margin-left: 18px;
}
  }
  .complateline{background:  #3D31E2;
  width:200px;
height:4px;
border-radius:1px;
margin-left: 18px;
margin-bottom: 5px;}
.prosper {width: 378px;height: 140px;margin-left: 310px;display: inline-block;}
  .reset-form {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 0;
    top: 225px;
    margin: auto;
    height: 400px;
    width: 1000px;
    background-color: #fff;
    box-sizing: border-box;
    > p {
      height: 34px;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 34px;
      text-align: center;
      margin: 20px auto;
    }
    >div {
      font-size:20px;
      font-family:Microsoft YaHei;
      font-weight:400;
      display: block;
      height: 34px;
      line-height: 34px;
      text-align: center;
      > span{color: #3743D6;cursor: pointer;}
    }
  }
}
</style>